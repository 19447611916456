/* Treatment.css */

#treatments {
  padding: 40px 20px;
  background-color: #f0f0f0;
}

#treatments .treatment-wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

#treatments .treatment-title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #333;
  font-family: 'Poppins', sans-serif;
}

#treatments .treatment-carousel {
  margin: 0 auto;
  padding: 20px;
}

#treatments .treatment-item {
  background-color: #333;
  border: 1px solid #444;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px; /* Fixed height */
  width: 300px; /* Fixed width */
  margin: 0 15px; /* Space between cards */
}

#treatments .treatment-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

#treatments .treatment-name {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 10px;
  color: #f1c40f;
  font-family: 'Poppins', sans-serif;
}

#treatments .treatment-description {
  color: #ccc;
  font-family: 'Poppins', sans-serif;
  flex-grow: 1;
}

/* Responsive Design */
@media (max-width: 768px) {
  #treatments .treatment-item {
    margin: 10px 0; /* Adjust margin for small screens */
    height: auto; /* Adjust height for small screens */
    width: auto; /* Adjust width for small screens */
  }
}
