.footer {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}
.footer h3 {
  margin-bottom: 15px;
  font-size: 1.5rem; /* Larger and bold heading for better emphasis */
  color: #f1c40f; /* Highlight color for headings */
}
.footer-left,
.footer-right {
  flex: 1;
  min-width: 250px;
  margin: 10px;
}

.footer-title {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #f1c40f;
}

.bold {
  font-weight: bold;
}

.footer-address {
  line-height: 1.6;
  margin-bottom: 10px;
}

.footer-right h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.footer-right p {
  margin: 5px 0;
}

.footer-right a {
  color: #fff;
  text-decoration: none;
}

.footer-right a:hover {
  text-decoration: underline;
}

.footer-bottom {
  margin-top: 20px;
  font-size: 0.9em;
}

.footer-bottom p {
  margin: 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-left,
  .footer-right {
    margin: 10px 0;
    text-align: center;
  }

  .footer-title {
    font-size: 1.2em;
  }

  .footer-right h3 {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .footer-title {
    font-size: 1em;
  }

  .footer-right h3 {
    font-size: 0.9em;
  }
}
